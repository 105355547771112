<template>
  <div class="list grid grid-12">
    <div class="cell">
      <h4 class="typo--s">
        {{ firstItem.title }}
      </h4>
      <div :class="['grid grid-12', firstItem.content.length < 2 ? 'grid-full' : false]">
        <div
          v-for="(content, contentIndex) in firstItem.content"
          :key="contentIndex"
          v-html="content.text"
        />
      </div>
    </div>
    <div class="cell">
      <div
        v-for="(item, index) in secondColumn"
        :key="index"
        class="cell"
      >
        <h4 class="typo--s">
          {{ item.title }}
        </h4>
        <div :class="['grid grid-12', item.content.length < 2 ? 'grid-full' : false]">
          <div
            v-for="(content, contentIndex) in item.content"
            :key="contentIndex"
            v-html="content.text"
          />
        </div>
      </div>
    </div>
    <!-- <div
      v-if="showCredits"
      class="cell last typo--s"
      v-html="$store.state.wp.options.footer.description"
    /> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    // list: {
    //   type: Array,
    //   required: true,
    // },
    // showCredits: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
  },
  computed: {
    firstItem() {
      return this.data.items[0];
    },
    secondColumn() {
      return this.data.items.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  margin-top: 18px;
  flex: 0 0 100%;

  .page-homepage & {
    mix-blend-mode: difference;
  }

  .page-laconic & {
    color: var(--black);
  }

  @include mq(m) {
    & > *:not(:first-child){
      grid-column-start: 2;
    }
  }
}
.grid {
  grid-gap: 0 24px;
  @include mq(m) {
    grid-template-columns: 1fr 1fr;
  }

  &-full {
    @include mq(m) {
      grid-template-columns: 1fr;
    }
  }
}
.cell {
  &:not(:last-child) {
    margin-bottom: 18px;
    @include mq(m) {
      margin-bottom: 36px;
    }
  }
  &.last {
    grid-column-start: 1;
    transform: translateY(39px);
  }
}
::v-deep h4 {
  margin-bottom: 5px;
}
::v-deep del {
  text-decoration: none;
  padding: 1px 5px 0;
  border: solid 1px var(--black);
  border-radius: 100px;
  font-size: 39%;
  line-height: 39%;
  text-transform: uppercase;
  vertical-align: super;
  position: relative;
  top: 7px;
}
</style>
