<template>
  <div
    class="gallery-container"
    v-on="!$mq.isTouchDevice ? { mouseenter: showCursor } : {}"
    @mouseleave="hideCursor"
  >
    <div
      ref="gallery"
      :key="isMobile ? 'mobile' : 'desktop'"
      class="gallery"
    >
      <div
        v-for="item in (isMobile ? mobile : desktop)"
        :key="item.id"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          :data="Object.assign(item, { lazyload: false, ratio: false, object_fit: 'cover' })"
        />
      </div>
    </div>
    <div
      v-if="!$mq.isTouchDevice"
      class="prev"
      @mousemove="setCursor"
      @click="prev"
    />
    <div
      v-if="!$mq.isTouchDevice"
      class="next"
      @mousemove="setCursor"
      @click="next"
    />
    <div
      v-if="!$mq.isTouchDevice"
      id="cursor"
      :style="{display: cursor.display, top: cursor.top, left: cursor.left }"
    >
      {{ cursor.text }}
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      desktop: this.data.desktop,
      mobile: this.data.mobile,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        contain: 'false',
        autoPlay: !!this.$mq.isTouchDevice,
        // pauseAutoplayOnHover: false,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
      },
      cursor: {
        display: 'none',
        top: 0,
        left: 0,
        text: '',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$mq.isMobile;
    },
  },
  watch: {
    isMobile() {
      this.reinit();
    },
  },
  mounted() {
    this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  methods: {
    reinit() {
      this.gallery.destroy();
      this.$nextTick(() => {
        this.gallery = new Flickity(this.$refs.gallery, Object.assign(this.settings, this.extraSettings));
      });
    },
    setCursor(event) {
      this.cursor.text = event.target.className;
      this.cursor.top = `${event.clientY}px`;
      this.cursor.left = `${event.clientX}px`;
    },
    showCursor() {
      this.cursor.display = 'initial';
    },
    hideCursor() {
      this.cursor.display = 'none';
    },
    prev() {
      this.gallery.previous();
    },
    next() {
      this.gallery.next();
    },
  },
};
</script>

<style lang="scss">
.gallery-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
  cursor: none;

  .gallery {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    overflow: hidden;

    .flickity-viewport {
      height: calc(var(--vh) * 100) !important;
      overflow: visible;
      figure {
        height: 100%;
      }
    }
    .item {
      width: 100%;
      height: 100%;
    }

  }

  .prev,
  .next {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }

  #cursor {
    z-index: 1;
    position: fixed;
    pointer-events: none;
    mix-blend-mode: difference;
    text-transform: uppercase;
    font-size: 14px;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }
}
</style>
