import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Archive from '@/components/custom/Archive.vue';
import List from '@/components/custom/List.vue';
import Newsletter from '@/components/blocks/newsletter';

const components = new Map();

components.set('content', Content);
components.set('media', Media);
components.set('archive', Archive);
components.set('list', List);
components.set('newsletter', Newsletter);

export default components;
