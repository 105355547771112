<template>
  <transition
    name="cookie"
    appear
  >
    <div
      v-show="!cookie && init"
      class="cookie"
    >
      <Flex>
        <Icon
          name="close"
          size="s"
          theme="minimal"
          :fn="acceptCookie"
        />
        <div
          v-if="options.cookies.title"
          :v-html="options.cookies.title"
        />
        <div
          v-if="options.cookies.text"
          :v-html="options.cookies.text"
        />
        <button
          v-if="options.cookies.cta"
          :v-html="options.cookies.cta"
          @click="acceptCookie"
        />
      </Flex>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript } from '@/assets/js/utils';

import Icon from '@/components/ui/icon';

// eslint-disable-next-line no-undef
const id = __VUE_WORDPRESS__.state.options?.cookies?.id;

export default {
  name: 'Cookie',
  components: {
    Icon,
  },
  data() {
    return {
      init: false,
      cookie: !!Cookies.get(id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  watch: {
    transition(val) {
      if (!val) this.init = true;
    },
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (!item.init) {
            if (item.inline) {
              const s = document.createElement('span');
              s.style.display = 'none';
              s.innerHTML = item.script;
              document.getElementsByTagName('body')[0].appendChild(s);
            } else {
              loadScript(item.script_src);
            }
          }
        });
      }

      if (this.$gtag) {
        this.$gtag.config(
          {
            params: {
              anonymize_ip: false,
              send_page_view: false,
            },
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-enter-active,
.cookie-leave-active {
  transition: all 0.8s $ease-custom;
}
.cookie-enter, .cookie-leave-to {
  transform: translateY(100%) translateY(30px);
}
.cookie {
  position: fixed;
  z-index: 2000;
  left: $unit;
  bottom: $unit;
  width: calc(100% - #{$unit * 2});
}
</style>
