<template>
  <main>
    <pre>{{ post }}</pre>
  </main>
</template>

<script>
import data from '@/mixins/data';

export default {
  name: 'Single',
  mixins: [data],
};
</script>
