<template>
  <main>
    <div class="container theme-laconic homepage">
      <div class="wrapper">
        <Featured
          v-if="post.acf.type === 'video' && post.acf.video"
          :src="post.acf.video"
        />

        <Gallery
          v-if="post.acf.type === 'gallery' && post.acf.gallery"
          :data="post.acf.gallery"
        />

        <Content
          v-if="post.content && post.content.rendered"
          :data="{ rich_text: post.content.rendered}"
        />
      </div>
      <div
        v-if="!!video"
        class="sound-button"
      >
        <!-- href="https://sizzer.nl/"
      target="_blank" -->
        <a
          v-if="soundCreditsLink"
          :href="soundCreditsLink"
          class="sound-credit typo--s"
          target="_blank"
        >
          {{ soundCredits }}
        </a>
        <span
          v-else-if="soundCredits"
          class="sound-credit typo--s"
        >{{ soundCredits }}</span>
        <img
          :src="require(`@/assets/svg/${iconToggle}.svg`)"
          alt=""
          class="sound"
          @click="toggleMuted"
        >
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';
import Content from '@/components/custom/Content';
import Featured from '@/components/custom/Featured';
import { Gallery } from '@/components/media';

export default {
  name: 'Page',
  components: {
    Content,
    Featured,
    Gallery,
  },
  mixins: [data],
  data() {
    return {
      iconToggle: null,
      video: null,
    };
  },
  methods: {
    toggleMuted() {
      if (this.video) {
        if (this.video.muted) {
          this.video.muted = false;
          this.iconToggle = 'sound-off';
          this.$store.commit('SET_MUTE', false);
        } else {
          this.video.muted = 'muted';
          this.iconToggle = 'sound-on';
          this.$store.commit('SET_MUTE', 'muted');
        }
      }
    },
  },
  computed: {
    ...mapGetters(['muted', 'options']),
    soundCredits() {
      return this.options.extra?.sound_credits?.value;
    },
    soundCreditsLink() {
      return this.options.extra?.sound_credits_link?.value;
    },
  },
  mounted() {
    const video = this.$el.querySelector('.wrapper video');
    if (video) {
      video.muted = this.muted;
      this.iconToggle = this.muted ? 'sound-on' : 'sound-off';
      this.video = video;
    }
  },
};
</script>

<style lang="scss" scoped>

.wrapper {
  position: relative;
  padding: 40px 12px 52px;
  display: flex;
  flex-flow: row wrap;
  min-height: calc(var(--vh) * 100);
}

.sound-credit {
  color: var(--white);
}
.sound-button {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  line-height: 22px;
  mix-blend-mode: difference;
  padding: var(--spacer-s);
  align-items: center;
  justify-content: center;
  gap: var(--spacer-s);

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
</style>
