<template>
  <div
    class="content"
    v-html="data.rich_text"
  />
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

};
</script>

<style lang="scss" scoped>
.content {
  flex: 0 0 100%;
  .homepage & {
    max-width: 1100px;
    align-self: center;
    color: var(--white);
    mix-blend-mode: difference;
    pointer-events: none;
    user-select: none;
  }
}
::v-deep p {
  margin: 18px 0;
}

</style>
