export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  currentPost: (state) => state.currentPost,
  transition: (state) => state.transition,
  snackbar: (state) => state.snackbar,
  isLaconic: (state) => state.isLaconic,
  currentTime: (state) => state.currentTime,
  muted: (state) => state.muted,
  cubeTransition: (state) => state.cubeTransition,
};
