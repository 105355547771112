<template>
  <div class="grid grid-1">
    <component
      :is="components[data.type]"
      v-if="data[data.type]"
      :class="cols"
      :data="Object.assign(data[data.type], { lazyload: true, ratio: 'auto', object_fit: data.object_fit ? 'cover' : 'contain', layout: data.layout, embed: true })"
    />
  </div>
</template>

<script>
import { Figure, Video, Gallery } from '@/components/media';

export default {
  name: 'MediaSection',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      components: {
        default: Figure,
        image: Figure,
        video: Video,
        gallery: Gallery,
      },
    };
  },
  computed: {
    cols() {
      if (this.data.layout === 'full') {
        return 'full';
      }
      if (this.data.layout === 'right') {
        return 'right';
      }
      return 'left';
    },
  },
};
</script>

<style lang="scss" scoped>

.full {
  grid-column: auto / span 12;
  @include mq(m) {
    grid-column: auto / span 12;
  }
}

.left {
  grid-column: auto / span 12;
  @include mq(m) {
    grid-column: auto / span 9;
  }
}

.right {
  grid-column: auto / span 12;
  @include mq(m) {
    grid-column: 4 / span 9;
  }
}

</style>
