<template>
  <main>
    <div class="container theme-laconic">
      <div class="wrapper">
        <div class="title">
          {{ post.acf.title }}
        </div>
        <div
          v-if="post.acf.talents"
          class="talents"
        >
          <div class="section-title">
            Talents
          </div>
          <div
            v-for="(item, itemIdx) in post.acf.talents"
            :key="itemIdx"
            class="talent"
          >
            <span>{{ item.label }}&nbsp;</span>
            <span>{{ item.talent }}</span>
          </div>
        </div>
        <div v-if="post.acf.all_credits">
          <div
            ref="collapsible"
            :class="['collapsible', !creditsIsOpen ? 'hidden' : null ]"
          >
            <div
              class="credits"
            >
              <div
                v-for="(item, itemIdx) in post.acf.all_credits"
                :key="itemIdx"
                class="credit"
              >
                <span>{{ item.label }}&nbsp;</span>
                <span>{{ item.credit }}</span>
              </div>
            </div>
          </div>
          <div
            class="btn"
            @click="toggleCredits"
          >
            {{ creditsIsOpen ? 'Close' : 'All credits' }}
          </div>
        </div>

        <div
          v-if="post.acf.media.video.url"
          class="video grid grid-1"
        >
          <MediaSection
            :data="Object.assign(post.acf.media, { type: 'video' })"
          />
        </div>
        <Section
          v-for="(section, parentIndex) in post.acf.sections"
          :key="parentIndex"
          :layout="layout(section)"
          :class="`section--${parentIndex}`"
        >
          <Spacer
            v-for="(block, index) in section.blocks"
            :key="index"
            :bottom="block.block_spacer"
          >
            <component
              :is="components.get(block.acf_fc_layout)"
              :class="[
                'block',
                `block--${block.acf_fc_layout}`,
                block.block_classes && block.block_classes !== '' ? block.block_classes.split(' ') : null,
              ]"
              :data="block"
            />
          </Spacer>
        </Section>
        <!-- <pre style="margin-top: 50px; font-family: monospace; font-size: 20px; line-height: 1.2em;"><code>{{ post }}</code></pre> -->
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';
import components from '@/assets/js/components';
import MediaSection from '@/components/custom/media';
import Section from '@/components/section';

export default {
  name: 'SingleWork',
  components: {
    MediaSection,
    Section,
  },
  mixins: [data],
  data() {
    return {
      creditsIsOpen: false,
      theme: null,
      components,
    };
  },
  methods: {
    toggleCredits() {
      this.creditsIsOpen = !this.creditsIsOpen;
      if (this.creditsIsOpen) this.$refs.collapsible.style.setProperty('--full-height', `${this.$refs.collapsible.scrollHeight}px`);
    },
    layout(section) {
      const {
        section_background, section_classes, section_layout, section_text, section_id, section_wrapper, section_spacer,
      } = section;
      return {
        section_background, section_classes, section_layout, section_text, section_id, section_wrapper, section_spacer,
      };
    },
  },
  computed: {
    ...mapGetters(['isLaconic']),
  },
};
</script>

<style lang="scss" scoped>
main {
  background: var(--black);
  color: var(--white);
}

.wrapper {
  // font-family: helvetica, sans-serif;
  // letter-spacing: -0.02em;
  min-height: calc(100 * var(--vh));
  padding: 60px 12px;

  .title {
    margin-bottom: 24px;
    // font-size: 28px;
    // line-height: 32px;
  }
  .section-title {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 16px;
  }
  .collapsible {
    max-height: var(--full-height);
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  .hidden {
    max-height: 0;
  }
  .btn {
    margin-top: 20px;
  }
  .credits {
    padding-top: 30px;
  }
  .talent,
  .credit {
    font-size: 18px;
    line-height: 21px;
    @include mq($until: xs) {
      span {
        display: block;
      }
    }
  }
  .video {
    margin-top: 30px;
    cursor: pointer;

    @media (orientation: portrait) {
      ::v-deep .plyr--fullscreen-enabled.plyr--is-ios.plyr--is-touch.plyr--fullscreen-fallback {
        .plyr__video-embed {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    ::v-deep .plyr__controls {
      background: none;
      mix-blend-mode: difference;
    }
    ::v-deep .plyr--stopped .plyr__controls {
      display: none;
    }
    ::v-deep .plyr--video:not(.plyr--stopped) .plyr__control.plyr__control--overlaid {
      opacity: 0;
      visibility: hidden;
    }
    ::v-deep .plyr__control.plyr__control--overlaid {
      transform: translate(-50%, -50%) scale(4);
      mix-blend-mode: difference;
      @include mq(xxs) {
        transform: translate(-50%, -50%) scale(5.5);
      }
      svg {
        left: 0;
        position: static;
      }
    }

    > div {
      grid-column: auto / span 12;
      @include mq(m) {
        grid-column: auto / span 9;
      }
    }
  }
}
</style>
