var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Spacer',{staticClass:"grid grid-12 grid-m-1"},[_c('div',{staticClass:"content-container"},[(_vm.data?.label && _vm.data?.label !== '')?_c('Label',{attrs:{"data":{
        value: _vm.data.label,
        tag: 'p',
        size: 's'
      }}}):_vm._e(),(_vm.data?.title && _vm.data?.title !== '')?_c('Title',{attrs:{"data":{
        value: _vm.data.title,
        tag: 'h2',
        size: 'l'
      }}}):_vm._e(),(_vm.data?.rich_text && _vm.data?.rich_text !== '')?_c('RichText',{attrs:{"data":{
        value: _vm.data.rich_text,
        size: _vm.richTextSize
      }}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }