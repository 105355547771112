<template>
  <Spacer class="grid grid-12 grid-m-1">
    <div class="content-container">
      <Label
        v-if="data?.label && data?.label !== ''"
        :data="{
          value: data.label,
          tag: 'p',
          size: 's'
        }"
      />
      <Title
        v-if="data?.title && data?.title !== ''"
        :data="{
          value: data.title,
          tag: 'h2',
          size: 'l'
        }"
      />
      <RichText
        v-if="data?.rich_text && data?.rich_text !== ''"
        :data="{
          value: data.rich_text,
          size: richTextSize
        }"
      />
    </div>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    richTextSize() {
      if (this.data?.size_rich_text) {
        // if (this.$mq.isMobile && this.data.size_rich_text === 'l') {
        //   return 'm';
        // }

        return this.data.size_rich_text;
      }

      return 'm';
    },
  },
};
</script>

<style lang="scss">
.content {
  flex: 0 0 100%;
  .homepage & {
    max-width: 1100px;
    align-self: center;
    color: var(--white);
    mix-blend-mode: difference;
    pointer-events: none;
    user-select: none;
  }
}

.content-container {
  grid-column: auto / span 1;
  @include mq(m) {
    grid-column: auto / span 9;
  }
}
::v-deep p {
  margin: 18px 0;
}
</style>
