<template>
  <div class="grid grid-6 newsletter-container">
    <div
      v-if="!success"
      :class="['form', avoid ? 'form-avoid' : false]"
    >
      <Content
        :data="{ label: data.title, rich_text: data.rich_text }"
        class="newsletter-title"
      />
      <form
        class="newsletter-form"
        @submit.prevent="submit"
      >
        <input
          v-model="email"
          type="email"
          name="email"
          class="typo--l"
          placeholder="Your mail"
          required
        >
        <button class="typo--l">
          Subscribe
        </button>
      </form>
    </div>
    <div
      v-else
      class="success-message"
    >
      You subscribed!
    </div>
  </div>
</template>

<script>
import Content from '@/components/blocks/content';
import { mapGetters } from 'vuex';

export default {
  name: 'Newsletter',
  components: {
    Content,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.data.list_id || this.options.extra.mailchimp_list_id.value,
          email: this.email,
        },
      };
      this.$store.dispatch('sendRequest', request)
        .then((response) => {
          if (response.data.status && response.data.status === 'subscribed') {
            this.success = true;
          } else {
            this.success = false;
            this.avoid = false;
            console.log(response.data.title);
          }
        })
        .catch((error) => {
          this.success = false;
          this.avoid = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.newsletter-container > div{
   grid-column: auto / span 2;
   @include mq(m) {
     grid-column: auto / span 1;
   }
}

.form {
  &-avoid {
    cursor: progress;

    form {
      pointer-events: none;
    }
  }
}

.newsletter-title {
  margin-bottom: var(--spacer-xs);
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  width: fit-content;

  input {
    border: none;
    outline: none;

    &::placeholder {
      color: var(--grey);
    }

  }

  button {
    text-align: left;

    @include mq($and: $hover) {
      transition: 300ms cubic-bezier(0, 0.9, 1, 1);
      &:hover {
        color: #7000ff;
        padding-left: 12px;
      }
    }
  }
}

.success-message {
  color: var(--purple);
}
</style>
