<template>
  <div id="app">
    <AppHeader />

    <div
      :class="isTransition ? 'context3d' : false"
      :style="scrollTop"
    >
      <transition
        :name="transitionName"
        @before-enter="toggleContext"
        @after-leave="toggleContext"
      >
        <router-view
          :key="$route.path"
        />
      </transition>
    </div>

    <Loader />
    <Cookie v-if="options && options.cookies" />

    <News />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';
import AppHeader from '@/components/ui/header';
import News from '@/components/custom/News';
/* import SiteToggle from '@/components/ui/site-toggle'; */

export default {
  name: 'App',
  components: {
    AppHeader,
    Cookie,
    Loader,
    News,
  },
  mixins: [lazyload],
  data() {
    return {
      isTransition: false,
      scroll: 0,
      wasLaconic: null,
    };
  },
  computed: {
    ...mapGetters(['options', 'isLaconic', 'cubeTransition']),
    scrollTop() { return this.scroll ? { '--scroll-top': `${-this.scroll}px` } : {}; },
    transitionName() {
      return this.cubeTransition ? 'cube' : null;
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    this.wasLaconic = this.isLaconic;

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );
  },
  methods: {
    toggleContext() {
      this.isTransition = !this.isTransition;
      if (this.isTransition) {
        this.scroll = window.scrollY;
      } else {
        this.scroll = 0;
        this.wasLaconic = this.isLaconic;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

html {
  background: #000000;
}

.app-loaded {
  #loader {
    display: none;
  }
}
#app {
  position: relative;
  min-height: calc(var(--vh) * 100);

  font-size: clamp(26px, 5.4vw, 40px); // 26px @ 480w <--> 40px @ 740w
  line-height: clamp(26px, 5.4vw, 40px);
  letter-spacing: -0.02em;
  @include mq($until: xxxs) {
    font-size: 22px;
    line-height: 22px;
  }
  @include mq(xxl) {
    font-size: clamp(26px, 5.4vw, 54px);
    line-height: clamp(26px, 5.4vw, 54px);
  }

  a {
    @include mq($and: $hover) {
      &:not(.unstyled) {
        transition: 300ms cubic-bezier(0, 0.9, 1, 1);
        del {
          transition: 300ms cubic-bezier(0, 0.9, 1, 1);
        }
        &:hover {
          color: #7000ff;
          del {
            border-color: #7000ff;
          }
        }
        &:not(.only-col) {
          display: inline-block;
          &:hover {
            padding-left: 12px;
          }
        }
      }
    }
  }
}
.reel {
  position: absolute;
  z-index: 0;
}
.font-an {
  font-family: $serif;
}
.theme-laconic {
  background: var(--white);
  color: var(--black);
  ::selection {
    background-color: var(--black);
    color: var(--white);
  }
}

.btn {
  display: block;
  width: fit-content;
  background: var(--white);
  color: var(--black);
  mix-blend-mode: difference;
  text-decoration: none;
  padding: 1px 6px;
  border: solid 1px var(--white);
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  &-active {
    position: relative;
    background: var(--black);
    color: var(--white);
    padding-right: 20px;
    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: 3px;
      height: 12px;
      width: 12px;
      // transform: scale(1.1);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGc+CiAgICA8Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiByPSI1MCIgZmlsbD0id2hpdGUiIC8+CiAgICA8bGluZSB4MT0iMzAiIHkxPSIzMCIgeDI9IjcwIiB5Mj0iNzAiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAvPgogICAgPGxpbmUgeDE9IjcwIiB5MT0iMzAiIHgyPSIzMCIgeTI9IjcwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgLz4KICA8L2c+Cjwvc3ZnPg==);
      background-size: 12px;
      background-repeat: no-repeat;
    }
  }
}

/// -- CUBE ANIMATION --
.context3d {
  --anim-time: 1s;
  position: relative;
  width: 100%;
  height: calc(100*var(--vh));
  transform-style: preserve-3d;
  perspective: 1500px;
  animation: out-in calc(var(--anim-time)*0.5) cubic-bezier(0.2, 0, 0.6, 1) 2 alternate;
}
.cube-enter-active,
.cube-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform var(--anim-time) cubic-bezier(0.4, 0, 0.6, 1);
  transform-origin: center center calc(-50 * var(--vh));
  backface-visibility: hidden;
  will-change: transform;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}
.cube-enter {
  transform: translateZ(var(--trans, 0)) rotateX(-90deg);
}
.cube-leave-to {
  transform: translateZ(var(--trans, 0)) rotateX(90deg);
}
.cube-leave-active {
  .container {
    transform: translateY(var(--scroll-top, 0px));
  }
}
html.safari {
  .cube-enter-active,
  .cube-leave-active {
    transform: translateZ(calc(-50*var(--vh))) rotateX(0deg);
  }
  .cube-enter {
    transform: translateZ(calc(-50*var(--vh))) rotateX(-90deg);
  }
  .cube-leave-to {
    transform: translateZ(calc(-50*var(--vh))) rotateX(90deg);
  }
  .cube-leave {
    transition: none !important;
    transform: translateZ(calc(-50*var(--vh))) rotateX(0deg);
  }
}
@keyframes out-in {
  0% {transform: scale(1);}
  100% {transform: scale(0.8);}
}
</style>
