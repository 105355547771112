<template>
  <video
    ref="video"
    autoplay
    muted
    loop
    playsinline
  >
    <source
      v-if="src.video_webm"
      :src="src.video_webm"
      type="video/webm"
    >
    <source
      v-if="src.video_mp4"
      :src="src.video_mp4"
      type="video/mp4"
    >
  </video>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    src: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      wasLaconic: null,
    };
  },
  computed: {
    ...mapGetters(['isLaconic', 'currentTime']),
  },
  mounted() {
    this.$refs.video.addEventListener('loadeddata', this.setTime, { once: true });
    this.wasLaconic = this.isLaconic;
  },
  beforeDestroy() {
    let { laconic, an } = this.currentTime;
    const time = this.$refs.video.currentTime;
    if (this.wasLaconic) laconic = time;
    else an = time;
    this.$store.commit('SET_CURRENT_TIME', { laconic, an });
  },
  methods: {
    setTime() {
      this.$refs.video.currentTime = this.isLaconic ? this.currentTime.laconic : this.currentTime.an;
      if (this.$refs.video.paused) {
        this.$refs.video.play();
      }
    },
  },
};
</script>

<style scoped>

video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: 0;
    object-fit: cover;
  }

</style>
