export default {
  menu: (state, getters) => (name) => state.menus[getters.lang][name],
  request: (state) => ({ type, params }) => {
    let res = null;
    const slugs = [];
    if (params.slug && params.slug.length > 0) {
      params.slug.forEach((slug) => {
        if (state[type][params.lang][slug]) slugs.push(slug);
      });
      return slugs.length > 0 ? slugs : null;
    }

    const request = state[type].requests.find((req) => {
      if (Object.keys(req.params).length === Object.keys(params).length) {
        res = Object.keys(req.params).every((key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]));
      }
      return res;
    });

    if (request) {
      return request.data;
    }
    return res;
  },
  singleBySlug: (state) => ({
    type, slug, lang, site,
  }) => (site ? state[type][lang][`${site}-${slug}`] : state[type][lang][slug]),
  options: (state) => state.options,
  tags: (state) => (taxonomy) => (state.tags[taxonomy].default ? Object.values(state.tags[taxonomy].default) : []),
  news: (state) => Object.values(state.posts.default)[0],
};
