<template>
  <router-link
    :to="data.link"
    class="single"
    :style="!$mq.isMobile && isLarge ? { 'grid-column': 'auto / span 2'} : { 'grid-column': 'auto / span 1' }"
  >
    <div
      v-if="data.gds_featured_image"
      class="image"
    >
      <Figure
        :data="Object.assign(data.gds_featured_image, { lazyload: false, ratio: ratio, object_fit: 'cover' })"
      />
    </div>
    <div class="title">
      {{ data.acf.title }}
    </div>
    <div class="subtitle">
      {{ data.acf.subtitle }}
    </div>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'Thumb',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLarge() {
      if (this.data.acf?.thumb?.is_large === true) {
        return true;
      }
      return false;
    },
    ratio() {
      if (this.data.acf?.thumb?.ratio === 'vertical') {
        return (16 / 9);
      }
      if (this.data.acf?.thumb?.ratio === 'original') {
        return 'auto';
      }
      return (9 / 16);
    },
  },
};
</script>

<style lang="scss" scoped>
.single {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
}
.image {
  display: block;
  position: relative;
  height: fit-content;
  // padding-bottom: 56.25%;
  & > * {
    // position: absolute;
    // top: 0;
    // left: 0;
    height: fit-content;
    width: 100%;
    object-fit: cover;
  }
}
.title {
  margin-top: 5px;
  font-size: 18px;
  line-height: 21px;
  @include mq(xxs) {
    font-size: 28px;
    line-height: 32px;
  }
}
.subtitle {
  max-width: 100%;
  margin-top: 7px;
  font-size: 14px;
  line-height: 16px;
  @include mq(xs) {
    max-width: 70%;
  }
  @include mq(xxs) {
    font-size: 18px;
    line-height: 18px;
  }
}

</style>
