export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_SITE(state, value) {
    state.isLaconic = !!value;
  },
  SET_CURRENT_TIME(state, value) {
    state.currentTime = value;
  },
  SET_MUTE(state, value) {
    state.muted = value;
  },
  SET_CUBE_TRANSITION(state, value) {
    state.cubeTransition = value;
  },
};
