<template>
  <div class="wrapper theme-laconic">
    404.<br>
    Page not found.
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound',
  computed: {
    ...mapGetters(['isLaconic']),
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: calc(100 * var(--vh));
  padding: 60px 12px;
}
</style>
