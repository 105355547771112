<template>
  <section>
    <div class="filters">
      <div
        v-for="tag in tags(data.archive)"
        :key="tag.id"
        :class="['btn', filters.includes(tag.id) ? 'btn-active' : false]"
        @click="setFilter(tag, $event)"
      >
        <span>{{ tag.name }}</span>
      </div>
    </div>
    <div class="work-list grid grid-2">
      <Thumb
        v-for="item in items"
        :key="item.id"
        class="unstyled"
        :data="item"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import archive from '@/mixins/archive';
import Thumb from '@/components/custom/Thumb';

export default {
  name: 'Archive',
  components: {
    Thumb,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['tags']),
  },
};
</script>

<style lang="scss" scoped>

section {
  width: 100%;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
  margin-top: 140px;
  .btn {
    letter-spacing: 0;
  }
  // flex gap workaround for safari <14.1
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      margin-bottom: 6px;
    }
    & > *:not(:last-child) {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
.work-list {
  padding-top: 20px;
  row-gap: 42px;
  grid-template-columns: repeat(1, 1fr);
  @include mq(m) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 12px;
  }
}

</style>
