<template>
  <header :class="['header', 'font-laconic']">
    <router-link
      class="unstyled"
      :to="navigation.items[0].url"
    >
      {{ navigation.items[0].content }}
    </router-link>
    <router-link
      class="unstyled"
      to="/"
    >
      <img
        :src="require(`@/assets/svg/laconic_talent_management.svg`)"
        class="logo"
      >
    </router-link>
    <router-link
      class="unstyled"
      :to="navigation.items[1].url"
    >
      {{ navigation.items[1].content }}
    </router-link>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      iconToggle: 'sound-off',
    };
  },
  computed: {
    ...mapGetters(['menu', 'isLaconic']),
    navigation() {
      return this.menu('Header-LACONIC');
    },
  },
  methods: {
    // toggleMuted() {
    //   if (document.querySelector('.reel video')) {
    //     if (document.querySelector('.reel video').muted) {
    //       document.querySelector('.reel video').muted = false;
    //       this.iconToggle = 'sound-on';
    //     } else {
    //       document.querySelector('.reel video').muted = 'muted';
    //       this.iconToggle = 'sound-off';
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.header {
  --height: clamp(26px, calc(0.77vw + 22.3px), 28px);
  @include mq($until: xxxs) {
    --height: 22px;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  font-size: var(--height); // 26px @ 480w <--> 28px @ 740w
  line-height: var(--height);
  letter-spacing: -0.02em;
  color: var(--white);
  mix-blend-mode: difference;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  user-select: none;

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: var(--height);
    padding-top: 3px;
    padding-bottom: 4px;
  }

  a,
  button {
    pointer-events: auto;
  }

  .router-link-exact-active {
    pointer-events: none;
  }
}

</style>
