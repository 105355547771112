<template>
  <section
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :class="[
      layout?.section_classes && layout?.section_classes !== '' ? layout?.section_classes.split(' ') : null,
      `spacer--${sectionSpacer}`,
    ]"
    tag="section"
    :template="sectionSpacer"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sectionSpacer() {
      let template = 'section';

      if (this.layout?.section_spacer === 's') template = 'section-small';
      else if (this.layout?.section_spacer === 'l') template = 'section-large';
      else if (this.layout?.section_spacer === '0') template = 'section-no-space';

      return template;
    },
  },
};
</script>
