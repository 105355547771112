export default {
  ADD_ITEM(state, {
    type, item, lang, site,
  }) {
    if (item && type
      && (!state[type][lang][item.slug]
        || (site && !state[type][lang][`${site}-${item.slug}`])
      )) {
      if (site) {
        state[type][lang][`${site}-${item.slug}`] = item;
      } else {
        state[type][lang][item.slug] = item;
      }
    }
  },
  ADD_REQUEST(state, { type, request }) {
    const array = { ...JSON.parse(JSON.stringify(request)) };
    state[type].requests.push(array);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      if (!state.pages[lang]) {
        state.pages[lang] = {};
      }

      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base;
        if (!state[restBase][lang]) {
          state[restBase][lang] = {};
        }
      });
    });
  },
};
