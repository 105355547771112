<template>
  <main>
    <div class="wrapper theme-laconic">
      <Section
        v-for="(section, parentIndex) in post.acf.sections"
        :key="parentIndex"
        :layout="layout(section)"
        :class="`section--${parentIndex}`"
      >
        <Spacer
          v-for="(block, index) in section.blocks"
          :key="index"
          :bottom="block.block_spacer"
        >
          <component
            :is="components.get(block.acf_fc_layout)"
            :class="[
              'block',
              `block--${block.acf_fc_layout}`,
              block.block_classes && block.block_classes !== '' ? block.block_classes.split(' ') : null,
            ]"
            :data="block"
          />
        </Spacer>
      </Section>
    </div>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';

export default {
  name: 'Page',
  components: {
    Section,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  methods: {
    layout(section) {
      const {
        section_background, section_classes, section_layout, section_text, section_id, section_wrapper, section_spacer,
      } = section;
      return {
        section_background, section_classes, section_layout, section_text, section_id, section_wrapper, section_spacer,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

main {
  background: var(--black);
  color: var(--white);
}

.wrapper {
  min-height: calc(100 * var(--vh));
  padding: 60px 12px;
}
</style>
