<template>
  <div
    v-if="news"
    v-show="shown && $route.name == 'Index'"
    class="news-container"
  >
    <Flex
      align="center"
      justify="space-between"
    >
      <Flex
        align="center"
        gap
      >
        <Icon
          name="close"
          size="s"
          theme="minimal"
          :fn="closeNews"
        />
        <Content
          v-if="news?.acf?.content"
          :data="{rich_text: news?.acf?.content, size_rich_text: 's'}"
        />
      </Flex>
      <Cta
        v-if="news?.acf?.link?.url"
        :data="{title: linkTitle, url: news?.acf?.link?.url, size: 's', unstyled: true}"
        class="news-cta"
      />
      <!-- <router-link
        v-if="news?.acf?.link?.url"
        :to="news?.acf?.link?.url"
        class="unstyled typo--s news-cta"
      >
        {{ linkTitle }}
      </router-link> -->
    </Flex>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import { mapGetters } from 'vuex';
import Content from '@/components/blocks/content';
import Icon from '@/components/ui/icon';
import Cta from '@/components/typo/cta';

export default {
  name: 'News',
  components: {
    Content,
    Icon,
    Cta,
  },
  data() {
    return {
      shown: false,
    };
  },
  computed: {
    ...mapGetters(['news']),
    linkTitle() {
      return this.news?.acf?.link?.title && this.news?.acf?.link?.title !== '' ? this.news?.acf?.link?.title : 'Discover more';
    },
  },
  mounted() {
    window.addEventListener('iubenda-ready', this.iubendaCheck);
    window.addEventListener('iubenda-closed', this.iubendaCheck);
  },
  beforeDestroy() {
    window.removeEventListener('iubenda-ready', this.iubendaCheck);
    window.removeEventListener('iubenda-closed', this.iubendaCheck);
  },
  methods: {
    iubendaCheck(e) {
      if (e.type === 'iubenda-closed' || window?._iub.cs?.consent?.id) {
        this.shown = true;
      }
    },
    closeNews() {
      this.shown = false;
    },
  },
};
</script>

<style lang="scss">
  .news-container {
    pointer-events: none;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    min-height: 40px;
    background: var(--purple);
    border-radius: 4px;
    color: var(--white);
    padding: var(--spacer-s);
    margin: var(--spacer-s);
    margin-bottom: var(--spacer-l2);
    // font-weight: bold;
    // font-size: 14px;
    // line-height: 14px;
    min-width: calc(100% - (2 * var(--spacer-s)));

    @include mq(m) {
      min-width: 33.3%;
      margin-bottom: var(--spacer-s);
    }

    .flex {
      height: 100%;
      column-gap: var(--row-gap-s);
      row-gap: var(--row-gap-s);
    }

    .icon-close {
      pointer-events: auto;
      cursor: pointer;

      path {
        fill: var(--white);
      }
    }

    .content {
      flex: auto;
      p {
        margin: 0;
      }
    }

    .news-cta {
      pointer-events: auto;
    }

    .news-cta a.unstyled {
      @include mq($and: $hover) {
        &:hover {
          color: var(--white);
        }
    }
    }

    @include mq($and: $hover) {
      &:hover {
        background: var(--black);
      }
    }

  }
</style>
