import medusa from '@/assets/js/observer';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      request: null,
      items: [],
      placeholder: 'Loading',
      offset: 0,
      filtersSlug: [],
      filters: [],
    };
  },
  computed: {
    ...mapGetters(['tags']),
  },
  created() {
    const queryFilter = this.$route.query.filter;
    if (typeof queryFilter === 'object' && queryFilter?.length > 0) {
      queryFilter.forEach((el) => {
        const value = this.tags(this.data.archive).find((tag) => tag.slug === el);
        if (value) {
          this.setFilter(value);
        }
      });
    } else if (typeof queryFilter === 'string' && queryFilter?.length > 0) {
      const value = this.tags(this.data.archive).find((tag) => tag.slug === queryFilter);
      if (value) {
        this.setFilter(value);
      }
    }
  },
  mounted() {
    this.setArchive();
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    setArchive() {
      this.setRequest();

      this.loadItems().then(() => {
        if (this.data.infinite_scroll) {
          this.$nextTick(this.setInfiniteScroll);
        } else {
          this.$root.$children[0].lazyObserve(this.$el);
        }
      });
    },
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        // this.items = [...this.items, ...items];
        this.items = items;
      }

      return items;
    },
    setRequest() {
      const { offset } = this;

      this.request = {
        type: this.data.archive,
        params: {
          per_page: this.data.infinite_scroll ? parseInt(this.posts_per_page, 10) || 12 : parseInt(this.max_posts, 10) || 100,
          offset,
          orderby: 'menu_order',
          order: 'asc',
          lang: this.$store.state.lang,
        },
      };

      const taxonomy = this.data.archive === 'work_laconic' ? 'tag_laconic' : 'tag_arianna_natale';
      if (this.filters && this.filters.length > 0) {
        this.request.params[taxonomy] = this.filters;
      } else {
        delete this.request.params[taxonomy];
      }
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget('infinite-scroll', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      this.offset += parseInt(this.posts_per_page, 10) || 12;
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) === document.body.offsetHeight) {
          this.requestOffset();
        }
      });
    },
    setFilter(tag, event) {
      if (this.filters.includes(tag.id)) {
        this.filters = this.filters.filter((el) => el !== tag.id);
        this.filtersSlug = this.filtersSlug.filter((el) => el !== tag.slug);
      } else {
        this.filters.push(tag.id);
        this.filtersSlug.push(tag.slug);
      }
      this.setArchive();

      if (event) {
        this.$router.push({ query: { ...this.$route.query, filter: this.filtersSlug } });
      }
    },

  },
};
